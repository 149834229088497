import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { EyecatchImage } from "./eyecatchImage"

const StyledSidebar = styled.aside`
  display: grid;

  row-gap: 30px;

  section {
    display: grid;
    row-gap: 30px;
    background-color: var(--white);
  }

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    align-content: start;
    grid-column: 9 / span 4 !important;
  }
`

const StyledProfile = styled.section`
  padding: 30px;

  figure {
    justify-self: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  strong {
    justify-self: center;
    font-size: var(--f3);
  }

  p {
    font-size: var(--f5);
  }

  a {
    color: var(--blue);
    justify-self: end;
  }
`

const StyledRecent = styled.section`
  padding: 30px 10px;

  h2 {
    justify-self: center;
    font-size: var(--f3);
    font-weight: normal;
    margin: 0;
  }

  article {
    display: grid;
    row-gap: 10px;
  }

  .textLink {
    text-align: center;
  }
`

export const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      wpUser {
        avatar {
          url
          width
          height
        }
        name
        description
      }
      allWpPost(sort: { fields: date, order: DESC }, limit: 6) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                sourceUrl
                title
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledSidebar>
      {data.wpUser.description && (
        <StyledProfile>
          <figure>
            <img
              src={data.wpUser.avatar.url}
              alt="profile"
              width={data.wpUser.avatar.width}
              height={data.wpUser.avatar.height}
            />
          </figure>
          <strong>{data.wpUser.name}</strong>
          <p>{data.wpUser.description}</p>
          <a
            href="https://twitter.com/radiologis2"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`>> Twitter`}
          </a>
        </StyledProfile>
      )}

      {data.allWpPost.edges.length > 1 && (
        <StyledRecent>
          <h2>最近の記事</h2>
          {data.allWpPost.edges.map(({ node }) => (
            <article key={node.id}>
              <Link to={`/blog/${node.slug}/`}>
                <EyecatchImage
                  featuredImage={node.featuredImage}
                  image={
                    node.featuredImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={node.title}
                />
              </Link>
              <Link to={`/blog/${node.slug}/`} className="textLink">
                {node.title}
              </Link>
            </article>
          ))}
        </StyledRecent>
      )}
    </StyledSidebar>
  )
}
