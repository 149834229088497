import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Alignwide, MyContainer } from "./styles"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faDollarSign,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faBitcoin } from "@fortawesome/free-brands-svg-icons"

const StyledHeader = styled.header`
  ${MyContainer}

  >div {
    ${Alignwide}

    display: grid;
    align-items: center;
    height: var(--headerHeight);
  }
`

const StyledLogo = styled(Link)`
  justify-self: start;
  font-size: var(--f2);
  color: #222;
`

const StyledNav = styled.nav`
  ${MyContainer}
  background-color: #222;
  color: var(--white);

  ul {
    ${Alignwide}
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, auto);
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: var(--f6);
  }

  a {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
    transition: background-color 0.3s;

    &:hover {
      background-color: #686868;
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    ul {
      grid-template-columns: repeat(6, auto);
      font-size: var(--f5);
    }

    a {
      column-gap: 5px;
      padding-left: 5px;
    }
  }
`

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const title = data.wp.generalSettings?.title || `Title`

  return (
    <>
      <StyledHeader>
        <div>
          <StyledLogo to={`/blog/`}>{title}</StyledLogo>
        </div>
      </StyledHeader>
      <StyledNav>
        <ul>
          <li>
            <Link to={`/blog/`}>
              <FontAwesomeIcon icon={faHome} />
              トップ
            </Link>
          </li>
          <li>
            <Link to={`/category/affiliate/`}>
              <FontAwesomeIcon icon={faDollarSign} />
              アフィリエイト
            </Link>
          </li>
          <li>
            <Link to={`/category/crypto/`}>
              <FontAwesomeIcon icon={faBitcoin} />
              仮想通貨
            </Link>
          </li>
          <li>
            <Link to={`/contact/`}>
              <FontAwesomeIcon icon={faEnvelope} />
              お問い合わせ
            </Link>
          </li>
        </ul>
      </StyledNav>
    </>
  )
}
