import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

const StyledFooterWrapper = styled.footer`
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  background-color: #222;
  color: var(--white);
`

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title || `Title`

  const thisYear = new Date().getFullYear()

  return (
    <StyledFooterWrapper>
      &copy; {thisYear} {title} |{" "}
      <Link to={`/privacy-policy/`}>Privacy Policy</Link>
    </StyledFooterWrapper>
  )
}
