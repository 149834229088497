import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { OtherImage } from "./otherImage"

export const EyecatchImage = ({ featuredImage, image, alt }) => {
  return (
    <>
      {featuredImage ? (
        <figure>
          {/* <img src={src} alt={alt} width={width} height={height} /> */}
          <GatsbyImage image={image} alt={alt} style={{ height: "100%" }} />
        </figure>
      ) : (
        <OtherImage />
      )}
    </>
  )
}
