import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const OtherImage = () => {
  return (
    <figure>
      <StaticImage
        src={`../images/ralafy_image.png`}
        alt=""
        layout={`fullWidth`}
        style={{ height: "100%" }}
      />
    </figure>
  )
}
