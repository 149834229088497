import * as React from "react"
import { Header } from "./header"
import { Footer } from "./footer"
import { createGlobalStyle } from "styled-components"
import { ForWordPress, Reset, Variables, WpBlock } from "./styles"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false

const GlobalStyle = createGlobalStyle`
  ${Reset}
	${Variables}
  ${ForWordPress}
  ${WpBlock}

  main {
    background-color: var(--background);
  }

  #gatsby-focus-wrapper {

    &:nth-child(2) {
      display: none;
    }
  }
`

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />

      <Header />

      {children}

      <Footer />
    </>
  )
}
